<template>
  <div class="fullScreen" :class="{ show: showEditDialog }" v-loading="loading">
    <Crumbs>
      <el-breadcrumb-item slot="after"
        >{{ form.list_s == 1 ? '审核' : '查看' }}商务信息
      </el-breadcrumb-item>
    </Crumbs>
    <div class="fullScreenMain" v-if="!showzhipai">
      <el-tabs v-model="activeName" class="fullScreenMainHeader">
        <el-tab-pane label="基本信息"></el-tab-pane>
        <el-tab-pane label="附件"></el-tab-pane>
        <!-- <el-tab-pane label="商务团队分工" v-if="form.id"></el-tab-pane> -->
        <el-tab-pane label="商务追踪" v-if="form.id"></el-tab-pane>
        <!-- <el-tab-pane label="商务绩效" v-if="form.id"></el-tab-pane> -->
      </el-tabs>

      <div class="fullScreenMainCon">
        <Manage
          width="600px"
          ref="Manage"
          :BusinessId="form.id"
          :options="options"
          v-show="activeName == 0"
          :isShowSuggest="true"
          :isBusinessLog="true"
          :disbursementShow="true"
          @showEditDialog="showEditDialog = true"
        ></Manage>
        <Annex :manageId="form.id" :file_Data="file_Data" v-show="activeName == 1"></Annex>
        <!-- <Assign
          width="600px"
          :ManageId="form.id"
          v-show="activeName == 2"
        ></Assign> -->
        <Business width="600px" :BusinessId="form.id" v-show="activeName == 2"></Business>
        <!-- <DistriBution
          width="600px"
          :options="{businessManageId:form.id}"
          :Disabled="true"
          v-if="activeName == 4"
        ></DistriBution> -->
      </div>
      <div class="fullScreenOperation">
        <el-button
          v-if="permission(['SWXX_DELETE'])"
          type="danger"
          :loading="loading"
          @click="delFn"
        >
          删除商务信息
        </el-button>
        <el-button v-if="form.list_s == 1" type="success" :loading="loading" @click="stats_c(1)">
          通过
        </el-button>
        <el-button v-if="form.list_s == 1" type="danger" :loading="loading" @click="stats_c(2)">
          不通过
        </el-button>
        <el-button
          v-if="
            form.list_s == 1 &&
            businessInformation &&
            businessInformation.isFinality &&
            businessInformation.status < 5
          "
          class="finality"
          :loading="loading"
          @click="stats_c(3)"
        >
          商务终结
        </el-button>
        <el-button type="info" @click="re">返回</el-button>
      </div>
    </div>
    <!-- <Principaluser
      width="600px"
      :options="form"
      v-if="showzhipai"
    ></Principaluser> -->

    <EditDialog :isShow.sync="dialogFormVisible">
      <div slot="title" class="title">{{ pass === 3 ? '商务终结' : '商务审批' }}</div>
      <div slot="content" class="content">
        <el-form :model="examineForm" :rules="rules_form" ref="ruleForm">
          <el-form-item prop="opinion" v-if="pass == 2 || pass == 3">
            <el-input
              type="textarea"
              placeholder="请输入原因"
              @input="$forceUpdate()"
              v-model="examineForm.opinion"
            ></el-input>
          </el-form-item>
          <el-form-item v-else>
            <el-input
              type="textarea"
              placeholder="通过"
              @input="$forceUpdate()"
              v-model="examineForm.opinion"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer">
        <el-button :type="pass === 3 ? 'info' : (pass === 1 ? 'success' : 'danger')" :loading="loading" @click="pass_no">{{
          pass === 3 ? '商务终结' : (pass === 1 ? '审批通过' : '审批不通过') 
        }}</el-button>
        <el-button type="warning" plain @click="dialogFormVisible = false">取消</el-button>
      </div>
    </EditDialog>
  </div>
</template>
<script>
import { mapState } from 'vuex'

import { numberToCurrencyNo, getInputValue, delcommafy } from '@/util/jsencrypt'

export default {
  components: {
    Crumbs: () => import('@/components/Crumbs.vue'),
    // Principaluser: () => import('./Principaluser.vue'),
    Annex: () => import('./Annex.vue'),
    Dictionary: () => import('@/components/Dictionary.vue'),
    EditDialog: () => import('@/components/EditDialog.vue'),
    Assign: () => import('@/views/publicView/Assign.vue'),
    Business: () => import('@/views/publicView/Business.vue'),
    DistriBution: () => import('@/views/publicView/DistriBution.vue'),
    Manage: () => import('@/views/publicView/Manage.vue'),
  },

  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: function () {
        return {}
      },
    },
  },
  computed: {
    ...mapState({
      userInfo: state => state.user.userInfo,
      businessInformation: state => state.business.businessInformation,
    }),
  },
  provide() {
    return {
      re_fun: this.re_fun,
    }
  },
  data() {
    return {
      showEditDialog: false,
      loading: false,
      submitLoading: false,
      form: {},
      activeName: '0',
      rules: {
        // userName: [{ required: true, message: '请输入用户姓名', trigger:['blur','change'] }],
        // loginName: [{ required: true, message: '请输入登录帐号', trigger:['blur','change'] }],
        // phone: [
        //     { required: true, message: '请输入联系电话', trigger:['blur','change'] },
        //     { pattern: phone, message: '请输入正确的联系电话', trigger: ['change', 'blur']},
        // ],
      },
      deptList: [], // 部门列表
      postList: [], //岗位列表
      type: 'portrait',
      file_Data: [],
      dialogFormVisible: false,
      examineForm: { opinion: '' },
      showzhipai: false,
      pass: 0,
      rules_form: {
        opinion: [{ required: true, message: '请输入审核意见', trigger: 'blur' }],
      },
    }
  },
  inject: ['re'],
  watch: {
    isShow: {
      immediate: true, //初始化立即执行
      handler: function (newVal) {
        // this.showEditDialog = newVal
      },
    },
    options: {
      immediate: true, //初始化立即执行
      deep: true, //对象深度监测
      handler: function (newVal, oldVal) {
        this.showzhipai = false
        this.init()
      },
    },
    pass(newVal) {
      if (newVal === 3) {
        this.rules_form.opinion[0].message = '请输入终结说明';
      } else {
        this.rules_form.opinion[0].message = '请输入审核意见';
      }
    },
  },
  created() {},
  methods: {
    finalityFn() {
      this.$confirm('是否确定终结该商务？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.loading = true

          this.$api.businessManage
            .finality({
              id: this.options.id,
              statusDescription: this.examineForm.opinion,
            })
            .then(res => {
              this.loading = false
              this.showEditDialog = false
              this.$message.success('终结成功！')
              this.re()
            })
            .catch(err => {
              this.loading = false
              console.log(err)
            })
        })
        .catch(() => {})
    },
    delFn() {
      this.$confirm('此操作将删除该商务信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.$message({
            type: 'success',
            message: '删除成功!',
          })
          this.$api.businessManage
            .deleteBusiness(this.options.id)
            .then(res => {
              this.showEditDialog = false
              this.re()
            })
            .catch(err => {
              console.log(err)
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
    Save(status) {
      if (status == 1) {
        //通过就移交计划经营部，等待通过
        this.form.status = 3
      } else if (status == 21) {
        this.form.status = 3
      }

      let Form = { ...this.$refs.Manage.form }
      delete Form.status
      let obj = { ...this.form, ...Form }
      obj.ourContractAmount = Number(delcommafy(obj.ourContractAmount))
      obj.predictMoney = Number(delcommafy(obj.predictMoney))
      obj.contentCooperationMoney = Number(delcommafy(obj.contentCooperationMoney))
      // obj.collaborationCosts = Number(delcommafy(obj.collaborationCosts))
      // obj.tenderFee = Number(delcommafy(obj.tenderFee))
      obj.opinionRecordRequst = {
        isCheckPass: 1,
        opinion: this.examineForm.opinion ? this.examineForm.opinion : '通过',
        dateValue: new Date().getTime(),
      }
      obj.tenderFee = Number(delcommafy(obj.tenderFee))
      obj.taxesFee = Number(delcommafy(obj.taxesFee))
      obj.actualStampTax = Number(delcommafy(obj.actualStampTax))
      obj.actualAdditionalTax = Number(delcommafy(obj.actualAdditionalTax))
      obj.contentCooperationRatio = Number(delcommafy(obj.contentCooperationRatio))
      // 筛选出修改过的字段
      const oldBusinessForm = this.$refs.Manage.oldBusinessForm
      let editArr = []
      Object.keys(oldBusinessForm).forEach(oldKey => {
        let old = oldBusinessForm[oldKey]
        if (typeof oldBusinessForm[oldKey] === 'string') {
          old = oldBusinessForm[oldKey].trim()
        }
        Object.keys(obj).forEach(key => {
          let e = obj[key]
          if (typeof obj[key] === 'string') {
            e = obj[key].trim()
          }
          if (
            oldKey === key &&
            old !== e &&
            !['principalUserName', 'suggestionPrincipalUserName'].includes(oldKey) &&
            !['principalUserName', 'suggestionPrincipalUserName'].includes(e)
          ) {
            editArr.push(key)
          }
        })
      })
      obj.changeFieldList = editArr
      this.editDialogLoading = true
      this.$api.businessManage
        .auditBusinessManage(obj)
        .then(res => {
          this.editDialogLoading = false
          this.dialogFormVisible = false
          this.re()
          this.$message.success('审批通过！')
          // this.$confirm("通过成功, 是否为其指定商务负责人?", "提示", {
          //   confirmButtonText: "确定",
          //   cancelButtonText: "取消",
          //   type: "warning",
          // })
          //   .then(() => {
          //     this.dialogFormVisible = false;
          //     this.showzhipai = true;
          //   })
          //   .catch(() => {
          //     this.dialogFormVisible = false;
          //     this.re();
          //   });
        })
        .catch(err => {
          this.editDialogLoading = false
          console.log(err)
        })
    },
    init() {
      this.editDialogLoading = true
      if (this.options.id) {
        this.$api.businessManage
          .getBusinessManage(this.options.id)
          .then(res => {
            this.editDialogLoading = false

            let arr = []
            if (res.data.businessFileList.length > 0) {
              for (let i = 0; i < res.data.businessFileList.length; i++) {
                const e = res.data.businessFileList[i]
                e.attachmentName = e.fileName
                e.attachmentPath = e.filePath
                arr.push(e)
              }
            }
            this.file_Data = arr
            res.data.businessManage.list_s = this.options.list_s
            this.form = res.data.businessManage
            this.form.applicantId = this.form.initiatorId
            this.form.ourContractAmount = numberToCurrencyNo(this.form.ourContractAmount)
            this.form.contentCooperationMoney = numberToCurrencyNo(
              this.form.contentCooperationMoney
            )
            // this.form.collaborationCosts = numberToCurrencyNo(this.form.collaborationCosts)
          })
          .catch(err => {
            this.editDialogLoading = false
            console.log(err)
          })
      } else {
        this.file_Data = []
      }
    },
    async submit() {
      if (this.form.status == 1) {
        this.form.status = 21
      }
      let Form = { ...this.$refs.Manage.form }
      delete Form.status
      Form.principalUserId = ''
      let obj = { ...this.form, ...Form }
      //千分位解析为整型
      obj.ourContractAmount = Number.parseInt(delcommafy(obj.ourContractAmount))
      obj.contentCooperationMoney = Number.parseInt(delcommafy(obj.contentCooperationMoney))
      // obj.collaborationCosts = Number.parseInt(delcommafy(obj.collaborationCosts))
      obj.tenderFee = Number.parseInt(delcommafy(obj.tenderFee))

      obj.opinionRecordRequst = {
        isCheckPass: 2,
        opinion: this.examineForm.opinion,
        dateValue: new Date().getTime(),
      }
      this.editDialogLoading = true
      this.$api.businessManage
        .auditBusinessManage(obj)
        .then(res => {
          this.editDialogLoading = false
          this.dialogFormVisible = false
          this.showEditDialog = false
          this.$message.success('审批不通过！')

          this.re()
        })
        .catch(err => {
          this.editDialogLoading = false
          console.log(err)
        })
    },
    is_number(el) {
      this.form[el] = this.form[el].replace(/\D/g, '')
    },
    inputMoney(el, name) {
      this.form[name] = getInputValue(el)
    },
    dataUpdate(row) {
      this.form.vatType = row.configName
      this.form.vatRate = row.configValue
      this.$set(this.form, 'vatRate', this.form.vatRate)
      // this.$forceUpdate()
    },
    stats_c(code) {
      //1通过，2不通过, 3商务终结
      if (code == 1) {
        let type = this.$refs.Manage.form?.businessType
        let Id = this.$refs.Manage.form?.principalUserId
        // let disbursementType = this.$refs.Manage.form?.disbursementType
        this.$refs.Manage.businessEditCheck()
        let isEditCheck = this.$refs.Manage?.isEditCheck
        if (!isEditCheck) {
          this.activeName = '0'
          this.$message.error('请填写必填信息')
          return
        }
        if (!type) {
          this.$message.warning('请选择商务类型')
          return
        }
        if (!Id) {
          this.$message.warning('请选择实际商务负责人')
          return
        }

        // if (!disbursementType) {
        //   this.$message.warning("请选择商务支出类型");
        //   return;
        // }
        // this.$refs.Manage.businessCheck()
        // let isCcheck = this.$refs.Manage?.isCcheck
        // if (isCcheck) {

        // }
      }
      this.pass = code
      this.examineForm.opinion = ''
      this.dialogFormVisible = true
    },
    pass_no() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.pass == 1) {
            this.Save(1)
          } else if (this.pass == 2) {
            this.submit()
          } else if (this.pass == 3) {
            this.finalityFn()
          }
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
// @import "@/styles/edit.scss";

@import '@/styles/config.scss';
.danwei {
  position: absolute;
  right: 2%;
}

.text-center-btm {
  margin-bottom: 10px;
  span {
    margin-right: 5px;
  }
  padding-top: 10px;
  .el-input {
    margin-left: 8px;
  }
}
/deep/.el-loading-spinner {
  margin-top: 21%;
}
/deep/.finality {
  background-color: #e9dcdc;
}
</style>
